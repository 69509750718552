<template>


  <div v-if="data.role_name" class="data_products">

  
      <UiButton                
        :tag="'NuxtLink'"
        :to="'products/edit/0'"
        :name="$t('brand_manager_view_tab_products_add_button')"
        />  
         

      <div style="clear:both">&nbsp;</div>

      <n-table :single-column="false" :single-line="true" class="products_table">
      
        <thead>
          <tr>
            <th>{{ $t('brand_manager_view_tab_products_name') }}</th>
            <th>{{ $t('brand_manager_view_tab_products_diaries_count') }}</th>
            <th>{{ $t('brand_manager_view_tab_products_desc') }}</th>
            <th></th>
          </tr>
        </thead>
  
        <tr v-for="item in data_products">

          <td class="nm">
             <NuxtLink class="itm" :to="item.link">
               <div class="img">
                 <img class="" :src="item.cover_s" alt="" data-not-lazy>
               </div>
               <div class="nm">
                 {{ item.name }}
               </div>
             </NuxtLink>
          </td>

          <td class="ac">
            {{ item.cnt_reports }}
          </td>

          <td class="ac">
            <i v-if="item.has_info" class="icon-check green-color"></i>
            <i v-if="!item.has_info" class="icon-minus-round red-color"></i>
          </td>

          <td class="ac">

              <UiButton                
                :tag="'NuxtLink'"
                :to="'products/edit/' + (item.id ? item.id : '0?action=edit_item&tmp_id=' + item.tmp_id)"
                :ico="'icon-pencil'"
                />
                
              <UiButton v-if="item.tmp_id"
                :type="'red'"
                :ico="'icon-remove'"
                :disabled="(item.cnt_harvests+item.cnt_active>0 ? true : false)"
                @click="removeProductConfirm(item.id || 0,item.tmp_id || 0)"
                />
                  
          </td>

        </tr>
      
      </n-table>

    </div>

</template>

<script setup>

import { NButton, NButtonGroup, NTable, useDialog, NDialogProvider, useMessage, NMessageProvider } from 'naive-ui'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const dialog = useDialog()
const message = useMessage()

const props = defineProps({
  data: {
    type: Object    
  }
});

const loadProducts = async () => {
  var res = await $api.getB2BProducts();
  // console.log()
  // return response;

  data_products.value = res.products;

  return res.products;

  // if(res.seed) data_products.value = res.seed;
  // else if(res.nutrient) data_products.value = res.nutrient;
  // else if(res.lamp) data_products.value = res.lamp;
  // else if(res.tent) data_products.value = res.tent;

  // if(res.seed) return res.seed;
  // else if(res.nutrient) return res.nutrient;
  // else if(res.lamp) return res.lamp;
  // else if(res.tent) return res.tent;
  
  // .then(res => {
  //   if(res){
  //     if(res.seed) data_products.value = res.seed;
  //     else if(res.nutrient) data_products.value = res.nutrient;
  //     else if(res.lamp) data_products.value = res.lamp;
  //     else if(res.tent) data_products.value = res.tent;
  //     return data_products
  //   }
  // })
}

const { pending: is_loading,  data: data_products } = await useLazyAsyncData('data_products', async () => await loadProducts())


const removeProductConfirm = (id,tmp_id) => {
  dialog.error({
    title: 'Delete',
    content: t('brand_manager_view_tab_products_delete_confirm'),
    positiveText: 'Yes',
    negativeText: 'Cancel',
    transformOrigin: 'center',
    onPositiveClick: () => {
      removeProduct(id,tmp_id);
    },
    onNegativeClick: () => {}
  })
}

const removeProduct = (id,tmp_id) => {
  if(!id && !tmp_id){
    return;
  }
  
  $api.deleteB2BProduct(id,tmp_id).then(res => {
    message.success('Saved')
    loadProducts();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}
    
/*
export default { 
  props: {
    data: {
      type: Object
    }
  },
  components: {
      NButton,
      NButtonGroup,
      NTable,
      useDialog,
      NDialogProvider,
      defineComponent,
      useI18n,
      useMessage,
      NMessageProvider
  },
  created() {  
    this.loadProducts();  
  },
  setup () {
    const { $api } = useNuxtApp()
    const { t } = useI18n()
    const dialog = useDialog()
    const message = useMessage()
    const data_products = ref([])

    const loadProducts = () => {
      $api.getB2BProducts().then(res => {
        if(res){
          if(res.seed) data_products.value = res.seed;
          else if(res.nutrient) data_products.value = res.nutrient;
          else if(res.lamp) data_products.value = res.lamp;
          else if(res.tent) data_products.value = res.tent;
        }
      })
    }

    const removeProduct = (id,tmp_id) => {
      if(!id && !tmp_id){
        return;
      }

      message.warning(t('brand_manager_view_generic_error'))
      return;
      
      $api.deleteB2BProduct(id,tmp_id).then(res => {
        if(res && res == 'success'){
          loadProducts();
        }
      })
      
    }
    
    const removeProductConfirm = (id,tmp_id) => {
      dialog.error({
        title: 'Delete',
        content: t('brand_manager_view_tab_products_delete_confirm'),
        positiveText: 'Yes',
        negativeText: 'Cancel',
        transformOrigin: 'center',
        onPositiveClick: () => {
          removeProduct(id,tmp_id);
        },
        onNegativeClick: () => {}
      })
    }

    return {
      removeProductConfirm,
      data_products,
      loadProducts
    }
  }
}
*/
</script>

<style scoped>
 
</style>

<style>
 
/* products_table */

.products_table {}

.products_table .itm {
  display: flex;
  align-items: center;
}

.products_table .itm .img {
  margin-right: 7px;
}

.products_table .itm .img img {
  min-width: 40px;
  max-width: 40px;
}

.products_table .itm .nm {}

.products_table .ac{
  width: 100px;
  vertical-align: middle;
}

.pointer{
  cursor: pointer;
}

.white-color{
  color: white;
}

.red-color{
  color: red;
}

.green-color{
  color: green;
}


</style>